import { useNavigate } from 'react-router-dom';
import BinaryQuizHoc from '../../../components/Pages/BinaryQuizHoc/BinaryQuizHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

function HaveCreativeBlock() {
  const navigate = useNavigate();
  
  useEffect(() => { 
    mixpanel.track('have_creative_block_page')
   }, [])


  function onAnswer(answer: boolean) {
    mixpanel.track('have_creative_block_selected', {
      'version': 1.0,
      'answer': answer ? 1 : 0
    });

    navigate('/have-not-equipment');
  }

  return (
    <BinaryQuizHoc
      label="can you relate to this statement?"
      title={`"It feels frustrating not knowing what to talk about."`}
      image={{ url: '/images/backgrounds/tired-man.png' }}
      onAnswer={onAnswer}
    />
  );
}

export default HaveCreativeBlock;
