import { useMemo, useState, useEffect } from 'react';
import GoBack from '../../../../components/UI/Buttons/GoBack/GoBack';
import TextField from '../../../../components/UI/TextField/TextField';
import './Step2.scss';
import { store } from '../../../../redux/store/Store';
import { actions as UserSliceActions } from '../../../../redux/UserSlice';
import { useNavigate } from 'react-router-dom';
import TermsPrivacySettings from '../../../../components/Agreements/TermsPrivacySettings/TermsPrivacySettings';
import Snakbar from '../../../../components/UI/Snakbar/Snakbar';
import { EmailTakenException } from '../../../../contracts/exceptions/auth/email-taken.exception';
import { EmailAuthenticationStrategy } from '../../../../components/Pages/AuthLoading/AuthStrategies';
import mixpanel from 'mixpanel-browser';

export interface IEmailAuthenticate {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

const emailAuthStrategy = new EmailAuthenticationStrategy;

function PlainAuthStep2() {
    useEffect(() => { 
        mixpanel.track('mail_sign_up_fullname_page')
       }, [])
    const navigate = useNavigate();
    const userSliceState = store.getState().user;

    const [isEmailTakenSnakbarActive, setIsEmailTakenSnakbarActive] = useState(false);

    const [firstName, setFirstName] = useState(userSliceState.firstName);
    const [lastName, setLastName] = useState(userSliceState.lastName);

    function onSetFirstName(value: string) {
        setFirstName(value);
        store.dispatch(UserSliceActions.setFirstName(value));
    }

    function onSetLastName(value: string) {
        setLastName(value);
        store.dispatch(UserSliceActions.setLastName(value));
    }

    const firstNameRules = [
        {
            pattern: /[\S.]+/,
            error: 'Please enter a valid first name',
        },
    ];

    const lastNameRules = [
        {
            pattern: /[\S.]+/,
            error: 'Please enter a valid last name',
        },
    ];

    const isFirstNameFieldValid = useMemo(() => !firstNameRules.some(rule => !rule.pattern.test(firstName)), [firstName]);
    const isLastNameFieldValid = useMemo(() => !lastNameRules.some(rule => !rule.pattern.test(lastName)), [lastName]);

    const isFinishButtonDisabled = useMemo(() => !isFirstNameFieldValid || !isLastNameFieldValid, [isFirstNameFieldValid, isLastNameFieldValid]);

    async function onFinishRegistrationClick() {
        const { email, password } = store.getState().user;

        const data: IEmailAuthenticate = {
            firstName,
            lastName,
            email,
            password,
        };

        try {
            const response = await emailAuthStrategy.authenticate(data);

            navigate('/auth/plain/callback', { state: response });
            return;
        } catch (error: any) {
            if (error instanceof EmailTakenException) {
                setIsEmailTakenSnakbarActive(true);

                setTimeout(() => navigate('/auth/plain/step-1'), 750);
                return;
            }
        }
    }

    return (
        <div className='page fade-in plain-auth-step-2-page'>
            <Snakbar
                isActive={isEmailTakenSnakbarActive}
                setIsActive={setIsEmailTakenSnakbarActive}
                text='Email is taken'
            />

            <header className='plain-auth-step-2-page__header'>
                <GoBack />
                <a href="/"><img className='logo' src="/images/logo.svg" alt="Logo"/></a>
                <img src="/images/step/step-10.svg" />
            </header>
            <main className='plain-auth-step-2-page__main'>
                <h1 className='plain-auth-step-2-page__main__title'>
                    Introduce yourself
                </h1>

                <div className="plain-auth-step-2-page__main__fields">
                    <TextField
                        label='First name'
                        value={firstName}
                        setValue={onSetFirstName}
                        rules={firstNameRules}
                    />

                    <br />

                    <TextField
                        label='Last name'
                        value={lastName}
                        setValue={onSetLastName}
                        rules={lastNameRules}
                    />
                </div>

                <button
                    disabled={isFinishButtonDisabled}
                    className='plain-auth-step-2-page__main__continue-btn'
                    onClick={onFinishRegistrationClick}
                >
                    Finish registration
                </button>

                <TermsPrivacySettings />
            </main>
        </div>
    );
}

export default PlainAuthStep2;
