import './BetweenStagesHoc.scss';
import GoBack from '../../../components/UI/Buttons/GoBack/GoBack';

export interface IBetweenStagesHoc {
    imageUrl: string;
    title: string
    subTitle: string;
    buttonText: string;
    onButtonClick(): void;
}

const BetweenStagesHoc = (props: IBetweenStagesHoc) => {
    return (
        <div
            className='between-stages-hoc page fade-in'
            style={{
                backgroundImage: `url(${props.imageUrl})`
            }}
        >
            <header className='between-stages-hoc__header'>
                <GoBack/>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <div></div>
            </header>

            <main className='between-stages-hoc__main'>
                <h1 className='between-stages-hoc__main__title'>{props.title}</h1>
                <h2 className='between-stages-hoc__main__sub-title'>{props.subTitle}</h2>
            </main>
            <footer className='between-stages-hoc__footer'>
                <button onClick={props.onButtonClick}>{props.buttonText}</button>
            </footer>
        </div>
    );
};

export default BetweenStagesHoc;
