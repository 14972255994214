import { useNavigate } from 'react-router-dom';
import BinaryQuizHoc from '../../../components/Pages/BinaryQuizHoc/BinaryQuizHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

function HaveNotEquipment() {
  const navigate = useNavigate();
  
  useEffect(() => { 
    mixpanel.track('have_no_equipment_page')
   }, [])

  function onAnswer(answer: boolean) {
    mixpanel.track('have_no_equipment_selected', {
      'version': 1.0,
      'answer': answer ? 1 : 0
    });

    navigate('/have-not-editing-skills');
  }

  return (
    <BinaryQuizHoc
      label="can you relate to this statement?"
      title={`"Without the right tools like a cameras or microphones, I feel restricted."`}
      image={{ url: '/images/backgrounds/confused-woman.png' }}
      onAnswer={onAnswer}
    />
  );
}

export default HaveNotEquipment;
