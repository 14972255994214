import './TermsPrivacySettings.scss';

function TermsPrivacySettings() {
  return (
    <div className="terms-privacy-settings">
      By signing up you agree with <a href="https://wois.io/terms" target="_blank">Terms and Conditions</a>
      <br />
      and read our <a href="https://wois.io/privacy" target="_blank">Privacy Policy</a>
    </div>
  );
}

export default TermsPrivacySettings;
