import './ReviewCard.scss';


export interface IReview {
  styles?: React.HTMLAttributes<HTMLDivElement>['style'];
  content: JSX.Element;
}

function ReviewCard(props: IReview) {
  return (
      <div className="review-card" style={props.styles}>
        <div className="review-card__card">
          {props.content}
        </div>
      </div>
  );
}

export default ReviewCard;
