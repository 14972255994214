import AuthLoading from '../../../components/Pages/AuthLoading/AuthLoading';
import { OAuthAuthenticationStrategy } from '../../../components/Pages/AuthLoading/AuthStrategies';
import { IdentityProviders } from '../../../contracts/auth.contract';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

function AuthLoadingApple() {
  const query = new URLSearchParams(window.location.search);
  const code = query.get('code')!;
  useEffect(() => { 
    mixpanel.track('auth_loading_apple_page')
   }, [])
   
  return <AuthLoading
    authStrategy={new OAuthAuthenticationStrategy}
    authData={
      { code, provider: IdentityProviders.APPLE }
    }
  />;
}

export default AuthLoadingApple;
