import constants from '../utils/constants';

export interface IStripeCustomer {
  id: string;
}

export interface ICreateStripeCustomer {
  email: string;
  metadata: {
      customer_user_id: string;
  };
}

export interface ILineItem {
  price: string;
  quantity: number;
}

export interface Discount {
  coupon?: string;
  promotion_code?: string;
}

export interface ICreatePaymentLink {
  stripeCustomer: { id: string };
  mode?: 'payment' | 'setup' | 'subscription';
  uiMode?: 'embedded' | 'hosted';
  lineItems: ILineItem[];
  metadata: Record<string, any>;
  urls: { success: string; cancel: string };
  discounts?: Discount[];
}

class Payments {
  async createStripeCustomer(options: ICreateStripeCustomer): Promise<IStripeCustomer> {
    const response = await fetch(`${constants.WOIS_ONBOARDING_ORIGIN}/create-stripe-customer`, {
        body: JSON.stringify(options),
        method: 'POST',
    });

    const customer: IStripeCustomer = await response.json();

    return customer;
  }

  async createPaymentLink(options: ICreatePaymentLink): Promise<string> {
    const response = await fetch(`${constants.WOIS_ONBOARDING_ORIGIN}/create-payment-link`, {
        body: JSON.stringify(options),
        method: 'POST',
    });

    const json = await response.json();

    return json.link;
  }
}

const paymentsService = new Payments();

export default paymentsService;
