import PersonalBrandStep1View from "./PersonalBrandStep1View";
import {useNavigate} from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

const PersonalBrandStep1 = () => {
    useEffect(() => { 
        mixpanel.track('personal_brand_step_one_page')
       }, [])

    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate('/auth')
    }

    return (
        <PersonalBrandStep1View handleSubmit={handleSubmit} />
    );
};

export default PersonalBrandStep1;
