import Icon from '../Icon/Icon';
import './Review.scss';
import ReviewCard from './ReviewCard/ReviewCard';

export interface IReviewer {
  avatarUrl: string;
  name: string;
  label: string;
}

export interface IReview {
  reviewer: IReviewer;
  comment: string;
  stars: number;
  styles?: React.HTMLAttributes<HTMLDivElement>['style'];
}

function Review(props: IReview) {
  return (
    <ReviewCard
      styles={props.styles}
      content={(
        <div className='review-card-inner'>
          <h3 className='review-card-inner__comment'>{props.comment}</h3>

          <br />

          <div className='review-card-inner__footer'>
            <img
              className='review-card-inner__footer__reviewer-avatar'
              src={props.reviewer.avatarUrl}
              alt={`${props.reviewer.name} avatar`}
            />

            <div className='review-card-inner__footer__right'>
              <div className='review-card-inner__footer__right__stars'>
                {new Array(props.stars).fill(0).map((_, i) => <Icon key={i} name='star' />)}
              </div>

              <h3 className='review-card-inner__footer__right__reviewer-name'>{props.reviewer.name}</h3>

              <div className='review-card-inner__footer__right__label'>{props.reviewer.label}</div>  
            </div>
          </div>
        </div>
      )}
    />
  );
}

export default Review;
