import { useNavigate } from 'react-router-dom';
import BinaryQuizHoc from '../../../components/Pages/BinaryQuizHoc/BinaryQuizHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

function SoundsLikeFullTimeJob() {
  const navigate = useNavigate();
  
  useEffect(() => { 
    mixpanel.track('sounds_like_fulltime_job_page')
   }, [])


  function onAnswer(answer: boolean) {
    mixpanel.track('sounds_like_fulltime_job_selected', {
      'version': 1.0,
      'answer': answer ? 1 : 0
    });

    navigate('/no-worries');
  }

  return (
    <BinaryQuizHoc
      label="don’t you worry"
      title="We know, it sounds like a full-time job, right?"
      image={{
        url: '/images/backgrounds/4-options-binary-quiz.png',
        disableShadow: true,
        objectFit: 'contain',
        padding: '0 min(.5rem, 1vh)'
      }}
      answerButtonOptions={{
        negativeText: 'No, it’s easy!',
        positiveText: 'Hell, yes!',
      }}
      onAnswer={onAnswer}
    />
  );
}

export default SoundsLikeFullTimeJob;
