import GoBack from '../../UI/Buttons/GoBack/GoBack';
import './WeHelpWithHoc.scss'

export interface IWeHelpWithHoc {
  label: string;
  title: string;
  mainContent: JSX.Element;
  textBelow: string;
  buttonText: string;
  onButtonClick(): void;
}

function WeHelpWithHoc(props: IWeHelpWithHoc) {
  return (
    <div className='we-help-with-hoc page fade-in'>
      <header className='we-help-with-hoc__header'>
          <GoBack />
          <a href="/"><img className='logo' src="/images/logo.svg" alt="Logo"/></a>
          <div></div>
      </header>

      <main className='we-help-with-hoc__main'>
        <div className="we-help-with-hoc__main__top">
          <h3 className='we-help-with-hoc__main__top__label'>{props.label}</h3>
          <h2 className='we-help-with-hoc__main__top__title'>{props.title}</h2>
        </div>

        <div className='we-help-with-hoc__main__main-content'>
          {props.mainContent}
        </div>
      </main>

      <footer className='we-help-with-hoc__footer'>
        <div className="we-help-with-hoc__footer__question">
          <span>{props.textBelow}</span>
        </div>

        <br />

        <button
          className='we-help-with-hoc__footer__button'
          onClick={props.onButtonClick}
        >
          {props.buttonText}
        </button>
      </footer>
    </div>
  );
}

export default WeHelpWithHoc;
