import { useMemo, useState, useEffect } from 'react';
import GoBack from '../../../../components/UI/Buttons/GoBack/GoBack';
import TextField from '../../../../components/UI/TextField/TextField';
import './Step1.scss';
import { store } from '../../../../redux/store/Store';
import { actions as UserSliceActions } from '../../../../redux/UserSlice';
import { useNavigate } from 'react-router-dom';
import TermsPrivacySettings from '../../../../components/Agreements/TermsPrivacySettings/TermsPrivacySettings';
import mixpanel from 'mixpanel-browser';

function PlainAuthStep1() {
    const navigate = useNavigate();
    const userSliceState = store.getState().user;
    useEffect(() => { 
        mixpanel.track('mail_sign_up_page')
       }, [])
    
    const [email, setEmail] = useState(userSliceState.email);
    const [password, setPassword] = useState(userSliceState.password);

    function onSetEmail(value: string) {
        setEmail(value);
        store.dispatch(UserSliceActions.setEmail(value));
    }

    function onSetPassword(value: string) {
        setPassword(value);
        store.dispatch(UserSliceActions.setPassword(value));
    }

    const emailRules = [
        {
            pattern: /[\S.]+/,
            error: 'Please enter e-mail address',
        },
        {
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            error: 'Please enter a valid e-mail address',
        },
    ];

    const passwordRules = [
        {
            pattern: /[\S.]+/,
            error: 'Please enter password',
        },
        {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
            error: 'Password must be at least 8 characters including a lower-case and upper-case letters, and a number',
        },
    ];

    const isEmailFieldValid = useMemo(() => !emailRules.some(rule => !rule.pattern.test(email)), [email]);
    const isPasswordFieldValid = useMemo(() => !passwordRules.some(rule => !rule.pattern.test(password)), [password]);

    const isContinueButtonDisabled = useMemo(() => !isEmailFieldValid || !isPasswordFieldValid, [isEmailFieldValid, isPasswordFieldValid]);

    function onContinue() {
        navigate('/auth/plain/step-2');
    }

    return (
        <div className='page fade-in plain-auth-step-1-page'>
            <header className='plain-auth-step-1-page__header'>
                <GoBack />
                <a href="/"><img className='logo' src="/images/logo.svg" alt="Logo"/></a>
                <img src="/images/step/step-8.svg" />
            </header>
            <main className='plain-auth-step-1-page__main'>
                <h1 className='plain-auth-step-1-page__main__title'>
                    Sign up with Email
                </h1>

                <div className="plain-auth-step-1-page__main__fields">
                    <TextField
                        label='Your email'
                        value={email}
                        setValue={onSetEmail}
                        rules={emailRules}
                    />

                    <br />

                    <TextField
                        type='password'
                        label='Your password'
                        value={password}
                        setValue={onSetPassword}
                        rules={passwordRules}
                    />
                </div>

                <button
                    disabled={isContinueButtonDisabled}
                    className='plain-auth-step-1-page__main__continue-btn'
                    onClick={onContinue}
                >
                    Agree and continue
                </button>

                <TermsPrivacySettings />
            </main>
        </div>
    );
}

export default PlainAuthStep1;
