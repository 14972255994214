// import  { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import BetweenStagesHoc from '../../components/Pages/BetweenStagesHoc/BetweenStagesHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

const LetsGetStarted = () => {
    const navigate = useNavigate();

    useEffect(() => { 
      mixpanel.track('lets_get_started')
     }, [])

    return (
      <BetweenStagesHoc
        imageUrl='/images/backgrounds/bg4.png'
        title='Great!'
        subTitle={'Achieve excellent results with \nminimal effort.'}
        buttonText='Let’s get started!'
        onButtonClick={() => navigate('/paywall')}
      />
    );
};

export default LetsGetStarted;
