import { CSSProperties } from 'react';
import GoBack from '../../UI/Buttons/GoBack/GoBack';
import './BinaryQuizHoc.scss';

export interface IBinaryQuizHocImageOptions {
  url: string;
  disableShadow?: boolean;
  objectFit?: CSSProperties['objectFit'];
  padding?: string;
}

export interface IBinaryQuizHocAnswerButtonsOptions {
  positiveText: string;
  negativeText: string;
}

export interface IBinaryQuizHoc {
  label: string;
  title: string;
  image: IBinaryQuizHocImageOptions;

  answerButtonOptions?: IBinaryQuizHocAnswerButtonsOptions;
  onAnswer: (answer: boolean) => void;
}

function BinaryQuizHoc(props: IBinaryQuizHoc) {
  return (
    <div
      className='binary-quiz-hoc page fade-in'
      style={({
        '--image-shadow-color': props.image.disableShadow ? 'transparent' : '#000',
      }) as any}
    >
      <header className='binary-quiz-hoc__header'>
          <GoBack />
          <a href="/"><img className='logo' src="/images/logo.svg" alt="Logo"/></a>
          <div></div>
      </header>

      <main className='binary-quiz-hoc__main'>
        <div className="binary-quiz-hoc__main__top">
          <h3 className='binary-quiz-hoc__main__top__label'>{props.label}</h3>
          <h2 className='binary-quiz-hoc__main__top__title'>{props.title}</h2>
        </div>

        <div className='binary-quiz-hoc__main__image'>
          <img
            src={props.image.url}
            style={{
              padding: props.image.padding,
              objectFit: props.image.objectFit,
            }}
          />
        </div>
      </main>

      <footer className='binary-quiz-hoc__footer'>
        <div className='binary-quiz-hoc__footer__answer-buttons'>
          <button
            onClick={() => props.onAnswer(false)}
          >
            {props.answerButtonOptions?.negativeText ?? 'No'}
          </button>
          <button
            onClick={() => props.onAnswer(true)}
          >
            {props.answerButtonOptions?.positiveText ?? 'Yes'}
          </button>
        </div>
      </footer>
    </div>
  );
}

export default BinaryQuizHoc;
