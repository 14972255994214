import { useNavigate } from 'react-router-dom';
import WeHelpWithHoc from '../../../components/Pages/WeHelpWithHoc/WeHelpWithHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

function ProduceContentInSeconds() {
  const navigate = useNavigate();

  useEffect(() => { 
    mixpanel.track('produce_content_in_sec_page')
    }, [])


  return (
    <div>
      <WeHelpWithHoc
        label="Produce content in seconds"
        title={'Record your video & we’ll transform it into engaging content.'}
        mainContent={<img src="/images/backgrounds/bg5.png" style={{ height: '100%', objectFit: 'contain' }} />}
        textBelow={'It’s like having a production team at your fingertips.'}
        buttonText="Wow, see more"
        onButtonClick={() => navigate('/get-professional-website')}
      />
    </div>
  );
}

export default ProduceContentInSeconds;
