import './PlainLoader.scss';

export interface IPlainLoader {
  /**
   * rem, px
   * default is 80px
   */
  size?: string;
  /**
   * default is #FE3838
   */
  color?: string;
  /** rem, px */
  borderSize?: string;
}

function PlainLoader(props: IPlainLoader) {
  return (<div
    className='plain-loader'
    style={
      {
        '--size': props.size ?? '80px',
        '--loader-color': props.color,
        borderSize: props.borderSize,
      } as any
    }
    />);
}

export default PlainLoader;
