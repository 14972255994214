// import  { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import BetweenStagesHoc from '../../components/Pages/BetweenStagesHoc/BetweenStagesHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

const NoWorries = () => {
    const navigate = useNavigate();

    useEffect(() => { 
      mixpanel.track('no_worries_page')
     }, [])

    return (
      <BetweenStagesHoc
        imageUrl='/images/backgrounds/bg3.png'
        title={'No worries, \nyou are not alone!'}
        subTitle={`\nThousands of people find it difficult to take the first step towards building their personal brands.\n\nThat's where we come in!`}
        buttonText='See how we can help!'
        onButtonClick={() => navigate('/we-spark-your-inspiration')}
      />
    );
};

export default NoWorries;
