import {Route, Routes} from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import Paywall from "../pages/Paywall/Paywall";
import Goals from "../pages/Quiz/Goals/Goals";
import Interests from "../pages/Quiz/Interests/Interests";
import SocialPresence from "../pages/Quiz/SocialPresence/SocialPresence";
import TimeInvestment from "../pages/Quiz/TimeInvestment/TimeInvestment";
import InFrontCameraComfort from "../pages/Quiz/InFrontCamera/InFrontCamera";

import VideoEffectiveness from "../pages/VideoEffectiveness/VideoEffectiveness";
import VideoCreationChallenges from "../pages/Quiz/VideoCreationChallenges/VideoCreationChallenges";
import PersonalBrandStep1 from "../pages/PersonalBrandStep1/PersonalBrandStep1";
import WelcomeAboard from "../pages/WelcomeAboard/WelcomeAboard";
import DownloadApp from "../pages/DownloadApp/DownloadApp";
import AuthLoadingGoogle from "../pages/Auth/AuthLoadingGoogle/AuthLoadingGoogle";
import AuthLoadingApple from "../pages/Auth/AuthLoadingApple/AuthLoadingApple";
import PlainAuthStep1 from "../pages/Auth/PlainSignUp/Step1/Step1";
import PlainAuthStep2 from "../pages/Auth/PlainSignUp/Step2/Step2";
import AuthLoadingPlain from "../pages/Auth/AuthLoadingPlain/AuthLoadingPlain";
import PaymentCallback from "../pages/PaymentCallback/PaymentCallback";
import GreatStart from "../pages/GreatStart/GreatStart";
import HaveCreativeBlocks from "../pages/BinaryQuiz/HaveCreativeBlocks/HaveCreativeBlocks";
import HaveNotEquipment from "../pages/BinaryQuiz/HaveNotEquipment/HaveNotEquipment";
import HaveNotEditingSkills from "../pages/BinaryQuiz/HaveNotEditingSkills/HaveNotEditingSkills";
import SoundsLikeFullTimeJob from "../pages/BinaryQuiz/SoundsLikeFullTimeJob/SoundsLikeFullTimeJob";
import HaveNoTime from "../pages/BinaryQuiz/HaveNoTime/HaveNoTime";
import NoWorries from "../pages/NoWorries/NoWorries";
import LetsGetStarted from "../pages/LetsGetStarted/LetsGetStarted";
import RecordWithEase from "../pages/WeHelpWithPages/RecordWithEase/RecordWithEase";
import ProduceContentInSeconds from "../pages/WeHelpWithPages/ProduceContentInSeconds/ProduceContentInSeconds";
import GetProfessionalWebsite from "../pages/WeHelpWithPages/GetProfessionalWebsite/GetProfessionalWebsite";
import WeSparkYourInspiration from "../pages/WeHelpWithPages/WeSparkYourInspiration/WeSparkYourInspiration";
import PayNow from "../pages/PayNow/PayNow";

export const useRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Goals/>}/>
            <Route path="/interests" element={<Interests/>}/>
            <Route path="/paywall" element={<Paywall/>}/>
            <Route path="/payment-callback" element={<PaymentCallback/>}/>
            <Route path="/social-presence" element={<SocialPresence/>}/>
            <Route path="/time-investment" element={<TimeInvestment/>}/>
            <Route path="/in-front-camera-comfort" element={<InFrontCameraComfort/>}/>

            <Route path="/great-start" element={<GreatStart/>}/>
            <Route path="/have-creative-blocks" element={<HaveCreativeBlocks/>}/>
            <Route path="/have-not-equipment" element={<HaveNotEquipment/>}/>
            <Route path="/have-not-editing-skills" element={<HaveNotEditingSkills/>}/>
            <Route path="/have-no-time" element={<HaveNoTime/>}/>
            <Route path="/sounds-like-full-time-job" element={<SoundsLikeFullTimeJob/>}/>

            <Route path="/no-worries" element={<NoWorries/>}/>
            <Route path="/we-spark-your-inspiration" element={<WeSparkYourInspiration/>}/>
            <Route path="/record-with-ease" element={<RecordWithEase/>}/>
            <Route path="/produce-content-in-seconds" element={<ProduceContentInSeconds/>}/>
            <Route path="/get-professional-website" element={<GetProfessionalWebsite/>}/>

            <Route path="/video-effectiveness" element={<VideoEffectiveness/>}/>
            <Route path="/video-creation-challenges" element={<VideoCreationChallenges/>}/>
            <Route path="/personal-brand-step1" element={<PersonalBrandStep1/>}/>
            <Route path="/welcome-aboard" element={<WelcomeAboard/>}/>
            <Route path="/download-app" element={<DownloadApp/>}/>

            <Route path="/lets-get-started" element={<LetsGetStarted/>}/>
            
            <Route path="/auth" element={<Auth/>}/>
            <Route path="/auth/plain/step-1" element={<PlainAuthStep1/>}/>
            <Route path="/auth/plain/step-2" element={<PlainAuthStep2/>}/>

            <Route path="/auth/plain/callback" element={<AuthLoadingPlain />} />
            <Route path="/auth/google/callback" element={<AuthLoadingGoogle />}/>
            <Route path="/auth/apple/callback" element={<AuthLoadingApple />}/>
            
            <Route path="/pay-now" element={<PayNow />}/>
        </Routes>
    )
}
