import { CSSProperties, ReactElement, useEffect, useRef, useState } from 'react';
import './Carousel.scss';
import Icon from '../Icon/Icon';

export interface ICarousel {
  items: ReactElement[];
  swipable?: boolean;
  /** in miliseconds */
  delay: number;
  wrapperTransparent?: boolean;
  cardStyles?: CSSProperties;
}

function Carousel(props: ICarousel) {
  const carouselWindowRef = useRef<HTMLDivElement>(null);
  const [isWindowUnderTouch, setIsWindowUnderTouch] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  function startCarousel(currentItemIndex: number = 0) {
    setCurrentIndex(currentItemIndex);

    const id = setTimeout(() => {
      startCarousel(currentItemIndex === props.items.length -1 ? 0: currentItemIndex + 1)
    }, props.delay) as any as number;

    setTimeoutId(id);
  }

  useEffect(() => {
    scrollToElement(currentIndex);
  }, [currentIndex]);

  function scrollToElement(index: number) {
    const carouselWindowNode = carouselWindowRef.current!;
    const carouselWidth = carouselWindowNode.offsetWidth;

    const currentCard = carouselWindowRef.current?.children[index] as HTMLElement;
    const currentCardWidth = currentCard.offsetWidth;
    const currentCardOffsetLeft = currentCard.offsetLeft;

    const left = currentCardOffsetLeft - (carouselWidth - currentCardWidth) / 2;

    carouselWindowNode.scroll({ behavior: 'smooth', left: left });
  }

  function swipe(side: 'left' | 'right') {
    clearTimeout(timeoutId);

    const swipeDestinatonIndex =
      side === 'left'
      ? Math.max(currentIndex - 1, 0)
      : Math.min(currentIndex + 1, props.items.length);

    scrollToElement(swipeDestinatonIndex);
  }

  function onScrollWindow(event: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (isWindowUnderTouch) {
      const currentCard = carouselWindowRef.current?.children[currentIndex] as HTMLDivElement;
      const target = event.target as HTMLDivElement;
      const swipeRange = target.offsetWidth / 4;
      const shouldSwipe = target.scrollLeft > currentCard.offsetLeft + swipeRange
        || target.scrollLeft < currentCard.offsetLeft - swipeRange;

      if (shouldSwipe) {
        const shouldSwipeLeft = target.scrollLeft < currentCard.offsetLeft;
        swipe(shouldSwipeLeft ? 'left' : 'right');
      }
    }
  }

  useEffect(() => {
    startCarousel();
  }, []);

  return (
    <div
      className='ui-carousel'
      onTouchStart={() => setIsWindowUnderTouch(true)}
      onTouchEnd={() => setIsWindowUnderTouch(false)}
      style={{
        touchAction: props.swipable ?  undefined : 'none',
        pointerEvents: props.swipable ?  undefined : 'none',
      } as any
    }>
      <div
        ref={carouselWindowRef}
        onScroll={onScrollWindow}
        className='ui-carousel__window'
      >
        {props.items.map((item, i) => {
          return (
            <div
              className={
                [
                  'ui-carousel__window__card',
                  props.wrapperTransparent
                    ? 'ui-carousel__window__card_transparent'
                    : '',
                ].join(' ')
              }
              style={props.cardStyles}
              key={i}
            >
              {item}
            </div>
          );
        })}
      </div>

      <br />

      <div className='ui-carousel__points'>
        {
          new Array(props.items.length)
            .fill(0)
            .map((_, index) => {
              return (
                <span
                  className='ui-carousel__points__point'
                  key={index}
                >
                  <Icon
                    name={index === currentIndex ? 'circle8-light': 'circle8-gray'}
                  />
                </span>
              );
            })
        }
      </div>
    </div>
  );
}

export default Carousel;
