import { useNavigate } from 'react-router-dom';
import WeHelpWithHoc from '../../../components/Pages/WeHelpWithHoc/WeHelpWithHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

function GetProfessionalWebsite() {
  const navigate = useNavigate();

  useEffect(() => { 
    mixpanel.track('get_professional_website_page')
    }, [])

  return (
    <div>
      <WeHelpWithHoc
        label="Get a professional website"
        title={'Showcase your insights & stories on a personalized website.'}
        mainContent={<img src="/images/wois-hanna-philips-page.png" style={{ height: '100%', objectFit: 'contain' }} />}
        textBelow={'Automatically build for you and ready to share with the world.'}
        buttonText="Nice, see more"
        onButtonClick={() => navigate('/lets-get-started')}
      />
    </div>
  );
}

export default GetProfessionalWebsite;
