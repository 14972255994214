import { useLocation } from 'react-router-dom';
import { IAuthResponse } from '../../../contracts/auth.contract';
import { MockEmailAuthenticationStrategy } from '../../../components/Pages/AuthLoading/AuthStrategies';
import AuthLoading from '../../../components/Pages/AuthLoading/AuthLoading';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

function AuthLoadingPlain() {
  useEffect(() => { 
    mixpanel.track('auth_loading_mail_page')
   }, [])

  const routerLocation = useLocation();

  const routeState: IAuthResponse = routerLocation.state;

  const authStrategy = new MockEmailAuthenticationStrategy(routeState);

  return <AuthLoading authStrategy={authStrategy} authData={undefined} />;
}

export default AuthLoadingPlain;
