import './GuideSlide.scss';

export interface IGuideSlide {
  imgSrc: string;
  label: string;
}

function GuideSlide(props: IGuideSlide) {
  return (
    <div className="paywall-guide-slide">
      <img
        className="paywall-guide-slide__image"
        src={props.imgSrc}
      />

      <br />

      <span
        className="paywall-guide-slide__label"
      >
        {props.label}
      </span>
    </div>
  );
}

export default GuideSlide;
