class CustomeEvent extends Event {
  message: any;

  constructor(eventType: string, message: any) {
    super(eventType);

    this.message = message;
  }
}

type EventCB = (message?: any) => void;

export class EventEmitter {
  private eventSource = document.createElement('div');

  on(event: string, cb: EventCB) {
    this.eventSource.addEventListener(event, event => cb((event as CustomeEvent).message));
  }

  onAll(events: string[], cb: EventCB) {
    const eventsSet = new Set(events);

    events.forEach((event) => {
      this.on(event, () => {
        if (eventsSet.size > 0) eventsSet.delete(event);
        if (eventsSet.size === 0) cb();
      })
    });
  }

  emit(event: string, message?: any) {
    const eventInstance = new CustomeEvent(event, message);

    this.eventSource.dispatchEvent(eventInstance);
  }
}

export default EventEmitter;
