import './PersonalBrandStep1.scss';
import GoBack from "../../components/UI/Buttons/GoBack/GoBack";

interface Props {
    handleSubmit: () => void,
}

const PersonalBrandStep1View = (props: Props) => {
    return (
        <div className='page personal-brand-step1-page fade-in'>
            <header style={{
                backgroundImage: 'url("./images/backgrounds/red-bg-top.svg")',
                backgroundSize: '80%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
                <GoBack/>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <div></div>
            </header>
            <main className='personal-brand-step1-page__main'>
                <div className='personal-brand-step1-page__main__text'>
                    <h2 className='personal-brand-step1-page__main__text__top'>
                        Great!
                    </h2>
                    <h3 className='personal-brand-step1-page__main__text__bottom'>
                        You’ve taken the first step in
                        <br />
                        crafting your personal brand!
                    </h3>
                </div>
            </main>
            <footer>
                <button onClick={props.handleSubmit}>Finalize setting up my profile</button>
            </footer>
        </div>
    );
};

export default PersonalBrandStep1View;
