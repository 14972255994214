import './Proposition.scss';
import Icon from '../../Icon/Icon';

export interface IProposition {
  icon?: string;
  title: string;
  description?: string;
}

function Proposition(props: IProposition) {
  return (
    <div className='ui-proposition'>
      {
        props.icon ? <div className='ui-proposition__icon'><Icon name={props.icon} /></div> : <></>
      }
      <div className='ui-proposition__text'>
        <span className='ui-proposition__text__title'>{props.title}</span>
        <span className='ui-proposition__text__description'>{props.description}</span>
      </div>
    </div>
  );
}

export default Proposition;
