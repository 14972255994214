import './SpecialOffer.scss'
import Timer from '../Timer/Timer';
import PlainLoader from '../Loaders/PlainLoader/PlainLoader';

export interface ISpecialOfferAd {
  /** delay in seconds */
  delay: number;
  title: string;
  price: number;
  discount: number;
  currency: '€' | '$';
  isLoading?: boolean;
  claimText?: string;
  animation?: 'popup' | 'fadeIn' | 'fadeInSequence';
  /** css time */
  animationDelay?: string;

  onClaim: () => void;
}

function SpecialOfferAd(props: ISpecialOfferAd) {
  const priceWithDiscount = props.price * (props.discount / 100);

  return (
      <div className="special-offer" style={{
        '--animation-name': props.animation,
        '--animation-duration': props.animationDelay,
      } as any}>
        <div className="special-offer__top">
          <h3 className='special-offer__top__title'>{props.title}</h3>
          <br />
          <div className='special-offer__top__pricing'>
            <div className='special-offer__top__pricing__price-description'>
              <span className='text-dase'>Best offer</span>

              <h2>{props.currency}{priceWithDiscount}/first month</h2>

              <h3 className='text-gray'>then {props.price}{props.currency}/month</h3>
            </div>
            <div className='special-offer__top__pricing__discount'>
              - {props.discount}%
            </div>
          </div>

          <div className='special-offer__top__timer'>
            Limited offer ends in &nbsp;<Timer delay={props.delay * 1000} />
          </div>
        </div>

        <div className='special-offer__bottom'>
          <button
            className='special-offer__bottom__claim-offer'
            onClick={props.onClaim}
            disabled={props.isLoading}
          >
            { props.isLoading
              ? <div className='special-offer__claim-offer__loader'>
                <PlainLoader size='1.9rem' />
              </div> 
              : props.claimText ?? 'Claim my offer'
            }
          </button>

          <div className='special-offer__bottom__pay-as-go'>
            Pay as you go, cancel any time.
          </div>
        </div>
      </div>
  );
}

export default SpecialOfferAd;
