import React, { useState } from 'react';
import './Goals.scss';
import ListWithSelects, {ListWithSelectsButtonData} from "../../../components/UI/ListWithSelect/ListWithSelect";
import Snakbar from '../../../components/UI/Snakbar/Snakbar';

interface GoalsViewProps {
    handleSelect: (value: string) => void,
    selectedItem: string[],
    DATA: ListWithSelectsButtonData[],
    handleSubmit: () => void,
}

const GoalsView = (props: GoalsViewProps) => {
    const [isSnakbarActive, setIsSnakbarActive] = useState(false);

    function onSubmit() {
        if (props.selectedItem.length) props.handleSubmit();
        else setIsSnakbarActive(true);
    }

    function onSelect(value: string) {
        if (isSnakbarActive) setIsSnakbarActive(false);

        props.handleSelect(value);
    }

    return (
        <div className='page goal-page'>
            <Snakbar isActive={isSnakbarActive} setIsActive={setIsSnakbarActive} text='Make your selection' />

            <header style={{
                backgroundImage: 'url("./images/backgrounds/red-bg-top.svg")',
                backgroundSize: '80%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <img src="./images/step/step-2.svg" alt="leaf"/>
            </header>
            <main>
                <h3>UNDERSTANDING YOUR GOALS</h3>
                <h2>Why do you want to build your personal brand?</h2>
                <br />
                <ListWithSelects
                    callback={onSelect}
                    selectedItem={props.selectedItem}
                    data={props.DATA}
                    multiSelect={true}
                />
            </main>
            <footer>
                <button onClick={onSubmit}>Next</button>
            </footer>
        </div>
    );
};

export default GoalsView;
