import './Timer.scss'

import { CSSProperties, useEffect, useMemo, useState } from 'react';
import IntToStringFormatter from '../../../utils/StringFormatter';

interface ITimer {
  /**
   * @prop {number} delay
   * integer in miliseconds
   */
  delay: number;
  bgColor?: CSSProperties['color'];
  textColor?: CSSProperties['color'];
}

/**
 * returns array in format [hours, minutes, seconds]
 */
function msToHMS(ms: number): [number, number, number] {
  let remainingSeconds = ms / 1000;

  const hours = Math.floor(remainingSeconds / 3600);
  remainingSeconds -= hours * 3600;

  const minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds -= minutes * 60;

  return [hours, minutes, remainingSeconds];
}

async function reduceSeconds(seconds: number, setter: React.Dispatch<React.SetStateAction<number>>): Promise<void> {
  if (seconds === 0) return;

  const remain = seconds - 1;

  setter(remain);

  setTimeout(() => reduceSeconds(remain, setter), 1000);
}

function Timer(props: ITimer) {
  const [delay, setDelay] = useState(props.delay / 1000);

  useEffect(() => { reduceSeconds(delay, setDelay) }, []);

  const [hours, minutes, seconds] = useMemo(() => msToHMS(delay * 1000), [delay]);

  return (
    <div
      className='ui-timer'
      style={{
        '--bg-color': props.bgColor,
        '--text-color': props.textColor,
      } as any}
    >
      <div className='ui-timer__hours'>{IntToStringFormatter.atLeast2Chars(hours)}</div>
      :
      <div className='ui-timer__minutes'>{IntToStringFormatter.atLeast2Chars(minutes)}</div>
      :
      <div className='ui-timer__seconds'>{IntToStringFormatter.atLeast2Chars(seconds)}</div>
    </div>
  );
}

export default Timer;
