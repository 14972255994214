import  { useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import BetweenStagesHoc from '../../components/Pages/BetweenStagesHoc/BetweenStagesHoc';
import mixpanel from 'mixpanel-browser';

const GreatStart = () => {
    const navigate = useNavigate();

    useEffect(() => { 
      mixpanel.track('great_start_page')
     }, [])

    return (
      <BetweenStagesHoc
        imageUrl='/images/backgrounds/bg2.png'
        title='Great start!'
        subTitle={`Let's now dive into what might be \nstopping you from building your brand.`}
        buttonText='Face the challenges'
        onButtonClick={() => navigate('/have-creative-blocks')}
      />
    );
};

export default GreatStart;
